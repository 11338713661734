import { MetaFunction } from "@remix-run/cloudflare";

import appStoreIcon from "../assets/app-store-icon.png";
import googlePlayIcon from "../assets/google-play-icon.png";
import desktopScreenshot from "../assets/desktop-screenshot.png";
import mobileScreenshot from "../assets/mobile-screenshot.png";
import { Link } from "@remix-run/react";

export const meta: MetaFunction = () => {
  return [
    { title: "PunchPocket" },
    {
      name: "description",
      content: "PunchPocket: Your Loyalty, Simplified.",
    },
  ];
};

export default function LandingPage() {
  return (
    <div className="w-full h-full bg-purple-950">
      <div className="bg-purple-950 h-svh md:max-w-7xl p-10 flex flex-col justify-start items-start md:justify-center md:items-center md:flex-row mx-auto text-white font-netflix md:p-24 text-4xl md:text-6xl font-bold">
        <div>
          <p>Your Loyalty,</p>
          <p className="mt-2">Simplified.</p>

          <div className="flex md:flex-col md:items-start md:gap-6 mt-10 gap-4">
            <Link
              to={"https://apps.apple.com/gb/app/punchpocket/id6503020257"}
              className="z-10"
            >
              <img
                className="h-10 md:h-16 w-auto object-contain"
                src={appStoreIcon}
                alt="App Store Download"
              />
            </Link>
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.moishinetzer.punchpocketuser"
              }
              className="z-10"
            >
              <img
                className="h-10 md:h-16 w-auto object-contain"
                src={googlePlayIcon}
                alt="Google Play Download"
              />
            </Link>
          </div>
        </div>

        <div className="md:hidden absolute inset-x-0 bottom-0 z-0 overflow-clip">
          <img
            className="h-[100svh] translate-y-64 object-cover w-auto"
            src={mobileScreenshot}
            alt="Mobile Screenshot"
          />
        </div>

        <div className="hidden md:block overflow-clip">
          <img
            className="h-[100svh] w-auto object-contain"
            src={desktopScreenshot}
            alt="Desktop Screenshot"
          />
        </div>
      </div>
    </div>
  );
}
